import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Layout from '../components/Layout';

function VideoPage({ data }) {
  const content = get(data, 'contentfulPage.content.childMarkdownRemark.html');

  return (
    <Layout>
      <div
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </Layout>
  );
}

export default VideoPage;

export const pageQuery = graphql`
  query VideoQuery {
    contentfulPage(pageId: { eq: "video" }) {
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
